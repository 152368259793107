/* @tailwind base;
@tailwind components;
@tailwind utilities; */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#holder{
  min-height: 100%;
  position:relative;
}

#body{
  padding-bottom: 100px;    /* height of footer */
}

.chat{
  height: 100px; 
  width:100%;
  position: relative;
  left: 0;
  bottom: 0;
  margin-left: 10px;
}

.chats {
  margin-left: 10px;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #bbbbbb;
  padding: 8px;
  border-radius: 10px;
}

.view_article {
  height: 300px;
  overflow-y: scroll;
}

.page-active {
  color: white;
  /* background: #5350ff; */
  padding: 6px;
  border-radius: 3px;
  text-decoration: none;
}

.page-number {
  background-color: #cfcfcfeb;
  border: 1px solid #c9c9c9;
}

.page-number > a {
  color: white;
  text-decoration: none;
}

.pagination > .active {
  background: #5350ff;
}

.table-height {
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 6px;
}

.doc_list div {
  /* background-color: #bbbbbb; */
  border: 1px solid black;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.doc_list div button {
  border-left: 1px solid black;
}
.doc_list {
  height: 100px;
  overflow-y: scroll;
}
.cursor-pointer {
  cursor: pointer;
}