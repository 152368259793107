@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html::-webkit-scrollbar{
    width: 10px;
}
html::-webkit-scrollbar-track{
    background: #e7e4e4;
    border-radius: 10px;
}
html::-webkit-scrollbar-thumb{
    background: var(--color-blue); 
    border-radius: 10px;
}
body{
    background: var(--color-gray);
    font-family: var(--font-poppins);
}

:root{
    --color-gray: #F8F8F8;
    --color-blue: #5B58E6;
    --color-black: #000000;
    --color-white: #ffffff;
    --color-text: #7C7C7C;
    --font-poppins: "Poppins", sans-serif;
}
.dark-mode{
    --color-gray: #171819;
    --color-black: #f9f9f9;
    --color-white: #1E2022;

}


main{
    overflow-x: hidden;
}
a{
    text-decoration: none;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
button,input{
    outline: none;
    border: none;
    background: none;
}
.form-control,
.form-select{
    box-shadow: none !important;
}
/* sidebar css */
.sidebar {
    background: var(--color-white);
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    padding: 25px;
    overflow: hidden;
    z-index: 99;
}
.sidebar-wraper {
    position: relative;
    min-height: 100vh;
}
.close-sidebar-btn,
.toggle-side-nav{
    display: none;
}
.sidebar .logo {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: var(--color-black);
    margin: 10px 0 60px;
    display: inline-block;
}

.sidebar .logo span {
    color: var(--color-blue);
}

.side-navs li a {
    padding: 13px 20px 13px 25px;
    display: block;
    transition: all .4s ease-in-out;
    width: calc(100% + 50px);
    margin-left: -25px;
    color: var(--color-black);
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
}

.side-navs li a span {
    margin-left: 25px;
    vertical-align: middle;
    display: inline-block;
}

.side-navs li a:hover, .side-navs li a.active {
    background: var(--color-blue);
    color: var(--color-white);
}

.side-navs li a:hover svg path, .side-navs li a.active svg path {
    fill: var(--color-white);
    fill-opacity: 1;
}


.upgrade-pro {
    padding: 40px 15px 20px;
    background: url('../img/upgrade-bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    background-position: top right;
    position: absolute;
    width: 100%;
    bottom: 50px;
}

.upgrade-pro h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-white);
    margin-bottom: 0;
}

.upgrade-pro h2 img {
    width: 22px;
    margin-left: 8px;
}

.upgrade-pro p {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-white);
}

.upgrade-pro .upgrade-btn {
    background: var(--color-white);
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-black);
    text-align: center;
    padding: 14px 30px;
    margin-top: 20px;
}

/* sidebar css end */

/* Right Side css */
.inner-content {
    padding: 25px;
    width: 100%;
    max-width: calc(100% - 300px);
    min-height: 100vh;
    margin-left: auto;
}
/* Right Side end*/

/* topbar css */

.search-box {
    max-width: 490px;
    height: 50px;
    background: var(--color-white);
    border-radius: 50px;
}

.search-box input {
    height: 100%;
    width: 80%;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
}

.search-box button {
    margin: 0 25px;
}

/* .search-box input::placeholder {
    color: var(--color-black);
} */


.right-setting-bar {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 12px;
}

.user-box button {
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: left;
}

.user-box button::after ,
.settings-box  button::after,
.notification-box  button::after{
    display: none;
}
#dropdown-basic:hover, #dropdown-basic.show {
    background-color: white;
}
.user-box button .user-img {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}
.user-box button .user-img img {
    width: 100%;
}
.user-box .dropdown-menu .user-info{
    display: none;
}
.user-box button .user-info h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: var(--color-black);
}
.user-box #user-profile {
    background-color: transparent;
    border: none;
    padding: 0;
}

.user-box button .user-info p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-blue);
    margin: 0;
}
.user-box .dropdown-menu a:hover {
    background: none;
    color: var(--color-blue);
}

.settings-box button {
    width: 50px;
    height: 50px;
    background: var(--color-white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-box .dropdown-menu a:hover {
    background: none;
    color: var(--color-blue);
}

.notification-box button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    border-radius: 50%;
}

.notification-box .dropdown-menu a:hover {
    background: none;
    color: var(--color-blue);
}

.notification-box .dropdown-menu li a {
    font-size: 14px;
    transition: .4s;
}

.notification-box .dropdown-menu li a span {
    color: var(--color-blue);
    margin-right: 10px;
    vertical-align: middle;
}

.topbar  .dropdown-menu li a:hover {
    background: none;
    color: var(--color-blue);
}
.topbar .dropdown-menu li a{
    font-size: 14px;
    transition: .4s;
}


.toggle-mode-btn {
    width: 100%;
    max-width: 160px;
    background: var(--color-white);
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    z-index: 1;
}

.toggle-mode-btn svg {
    margin: 0 auto;
    width: 1.5rem;
    height: 1.5rem;
}

.toggle-mode-btn span {
    margin: 0 auto;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: inherit;
}
.toggle-mode-btn:not(.dark-mode) .light {
    background: var(--color-gray);
}
.toggle-mode-btn.dark-mode .dark {
    background: var(--color-gray);
}
.toggle-mode-btn .dark svg {
    width: 1.15rem;
    height: 1.15rem;
}
.toggle-mode-btn.dark-mode svg path {
    fill: var(--color-blue);
}
.toggle-mode-btn.dark-mode .light svg path{
    fill: #F8F8F8;
}

/* topbar css end */


/* contnet box css */
.content-box{
    background: var(--color-white);
    margin-top: 25px;
    border-radius: 10px;
}
.articles-btn-wraper {
    padding: 100px 0 90px;
}
.article-page-btn {
    padding: 35px 35px;
    background: var(--color-gray);
    display: inline-block;
    border-radius: 15px;
    margin: 0 0 30px;
    color: var(--color-black);
    transition: .4s;
}
.article-page-btn:hover{
    background: var(--color-blue);
    color: var(--color-white);
}
.article-page-btn .text-box {
    position: relative;
}

.article-page-btn .text-box .tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    background: var(--color-blue);
    color: var(--color-white);
    border-radius: 50px;
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 15px;
    transition: .4s;
}
.article-page-btn:hover .text-box .tag {
    background: var(--color-white);
    color: var(--color-blue);
}
.article-page-btn .text-box h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
}

.article-page-btn .text-box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    margin-bottom: 0;
    transition: .4s;
}
.article-page-btn:hover .text-box p{
    color: var(--color-white);
}
.icon-box {
    width: 65px;
    height: 65px;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
}

/* contnet box css end */

/* single article writer css */
.article-input-box {
    background: var(--color-white);
    border-radius: 15px;
    padding: 18px 25px 25px;
    margin-bottom: 25px;
}

.article-input-box .sec-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.article-input-box .sec-title-box h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: var(--color-black);
    margin-bottom: 0;
}
.article-input-box .sec-title-box p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    color: var(--color-blue);
}
.article-input-box .sec-title-box:has(.title-with-date) {
    margin-bottom: 0;
}
.article-input-box .sec-title-box .title-with-date {
    max-width: 310px;
    margin-left: 10px;
}

.article-input-box .sec-title-box .redamore-btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    color: var(--color-blue);
}

.article-input-box .form-select {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
    height: 50px;
    border-radius: 50px;
    padding: 10px 30px;
    border-color: var(--color-gray);
    background-image: url('../img/down-arrow.svg');
    background-size: 12px;
    margin-bottom: 25px;
    background-color: var(--color-white);
}

.article-input-box .form-control {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    height: 50px;
    border-radius: 50px;
    border-color: var(--color-gray);
    padding-left: 28px;
    padding-right: 28px;
    background: var(--color-gray);
    color: var(--color-black);
}

.generate-mode .form-select{
    margin-bottom: 0;
}
.subheadings.article-input-box .add-heading-btn{
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-gray);   
    margin-left: 15px;
    transition: .4s;
}
.subheadings.article-input-box .add-heading-btn:hover{
    background: var(--color-blue);
}
.subheadings.article-input-box .add-heading-btn:hover svg path{
    fill: var(--color-white);
}
.subheadings.article-input-box .input-groups .form-group{
    margin-top: 10px;
}
.subheadings.article-input-box .input-groups .form-group .form-control{
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
}
/* .subheadings.article-input-box .input-groups .form-group .form-control::placeholder{
    color: #BFBFBF;
} */
.scroller{
    max-height: 190px;
    overflow-y: scroll;
    padding-right: 10px;
}

.scroller::-webkit-scrollbar{
    width: 6px;
}
.scroller::-webkit-scrollbar-track{
    background: #F8F8F8;
    border-radius: 15px;
}
.scroller::-webkit-scrollbar-thumb{
    background: var(--color-blue); 
    border-radius: 15px;
}
.article-input-box {
    background: var(--color-white);
    border-radius: 15px;
    padding: 18px 25px 25px;
    margin-bottom: 25px;
}
.generate-btn {
    background: var(--color-blue);
    color: var(--color-white);
    border-radius: 50px;
    padding: 14px 70px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: fit-content;
    display: block;
    margin-left: auto;
    border: 1px solid transparent;
    transition: .4s;
}
.generate-btn:hover {
    border-color: var(--color-blue);
    color: var(--color-blue);
    background: transparent;
}
.article-right-content{
    background: var(--color-white);
    border-radius: 10px;
    padding: 25px;
    height: 100%;
}

.article-right-content:has(.empty-title){
    display: flex;
    align-items: center;
    justify-content: center;
}
.article-right-content .empty-title{
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    color: #E3E3E3;
    text-align: center;
}

/* single article writer css end */

/* single article writer result css */
.article-right-content .feature-image {
    height: 285px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 25px;
}

.article-right-content .feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.article-right-content  .article-text-box h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-black);
}

.article-text-box h2, .article-text-box h1 {
    color: var(--color-black);
}

.article-right-content .article-text-box p {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-text);
}

.article-right-content .article-text-box .title-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.article-right-content .article-text-box .article-btns-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.article-right-content .article-text-box .article-btns-group p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-right: 15px;
}

.article-right-content  .article-text-box .article-btns-group button {
    width: 50px;
    height: 50px;
    background: var(--color-gray);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s;
}

.article-text-box .article-btns-group button:hover {
    background: var(--color-blue);
}

.article-text-box .article-btns-group button:hover img {
    filter: invert(0) brightness(100);
}

.article-right-content .article-text-box .scroller {
    max-height: 550px;
}

/* single article writer result css end */

/* tagnify css */
.tagify--outside{
    border: 0;
    width: 100%;
    position: static;
}

.tagify--outside .tagify__input{
  order: -1;
  flex: 100%;
  /* border: 1px solid var(--tags-border-color); */
  margin-bottom: 1em;
  transition: .1s;
}

.tagify--outside .tagify__input:hover{ border-color:var(--tags-hover-border-color); }
.tagify--outside.tagify--focus .tagify__input{
  transition:0s;
  border-color: var(--tags-focus-border-color);
}
/* tagnify css end */


/* Bulk article writer css */
.article-left-side .article-main-heading{
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: var(--color-black);
    margin-bottom: 20px;
}

.article-input-box .tags .tagify__input {
    font-weight: 400;
    font-size: 12px;
    line-height: 40px;
    height: 50px;
    border-radius: 50px;
    border-color: var(--color-gray);
    padding-left: 28px;
    background: var(--color-gray);
    color: var(--color-black);
    display: flex;
    align-items: center;
}
.article-input-box .tags .tagify__tag {
    background: rgba(91, 88, 230, 0.1) !important;
    border: 1px solid var(--color-blue);
    border-radius: 40px;
    overflow: hidden;
    padding: 6px;
    font-size: 12px;
    font-weight: 400;
}

.article-input-box .tags .tagify__tag>div {
    background: none;
}

.article-input-box .tags .tagify__tag>div::before {
    background: none !important;
    box-shadow: none !important;
}

.article-input-box .tags .tagify__tag__removeBtn {
    background: var(--color-blue);
    color: var(--color-white);
    width: 15px;
    height: 15px;
    line-height: 15px;
}

.tagify__tag>div span {
    color: var(--color-blue);
}

/* Bulk article writer css end */


/* Bulk article writer result css */
.crypto-box-wraper .title-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.crypto-box-wraper .title-wraper h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 0;
    color: var(--color-black);
}

.crypto-box-wraper .title-wraper .btn-edit {
    width: 30px;
    height: 30px;
}

.crypto-box-wraper .crypto-box {
    background: var(--color-white);
    padding: 18px;
    border-radius: 15px;
    margin: 0 12px;
}

.crypto-box-wraper .crypto-box h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    color: var(--color-blue);
}

.crypto-box-wraper .crypto-box p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
    margin-bottom: 0;
}


.bulk-article-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.bulk-article-search h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    color: var(--color-black);
}

.bulk-article-search .search-wraper form {
    display: flex;
    align-items: center;
    width: 220px;
    height: 40px;
    background: var(--color-white);
    border-radius: 100px;
    overflow: hidden;
}

.bulk-article-search .search-wraper form .form-control {
    border: none;
    box-shadow: none !important;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-black);
    background: none;
}

/* .bulk-article-search .search-wraper form .form-control::placeholder {
    color: var(--color-text);
} */

.bulk-article-search .search-wraper form button {
    color: var(--color-blue);
    margin: 4px 0 0 20px;
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
}

.article-input-box .btns-group button {
    width: 38px;
    height: 38px;
    background: var(--color-gray);
    border-radius: 50%;
    transition: .4s;
    margin-left: 5px;
}

.article-input-box .btns-group button:hover {
    background: var(--color-blue);
}

.article-input-box .btns-group button:hover img {
    filter: brightness(100);
}
.article-input-box .progress-wraper .progress {
    height: 10px;
    border-radius: 20px;
    margin: 6px 0;
    overflow: unset;
}
.article-input-box .progress-wraper .progress .progress-bar {
    position: relative;
    border-radius: inherit;
    overflow: unset;
}

.article-input-box .progress-wraper .progress .progress-bar::before {
    content: attr(aria-valuenow)'%';
    display: inline-block;
    width: fit-content;
    margin: 0 0 -40px auto;
    text-align: right;
    font-size: 12px;
    color: var(--color-black);
}
.article-input-box .progress-wraper .progress .progress-bar.que::before {
    display: none;
}
.article-input-box .progress-wraper .progress .progress-bar.completed {
    background-color: #5CB715;
}
.article-input-box .progress-wraper .progress .progress-bar.process {
    background-color: #7572FF;
}
.article-input-box .progress-wraper h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
    margin-bottom: 0;
}
.article-input-box .progress-wraper:has(.completed) h4{
    color: #5CB715;
}
.article-input-box .progress-wraper p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-blue);
    margin-bottom: 0;
}


.article-input-box .progress-wraper .progress .progress-bar span {
    display: none;
}


/* Bulk article writer result css end */



/* My Documents Single css */
.documents .article-input-box {
    position: relative;
    z-index: 1;
}

.documents .article-input-box::before {
    content: '';
    width: 30%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: inherit;
    z-index: -2;
    border: 1px solid var(--color-blue);
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    opacity: 0;
    transition: opacity .4s;
    pointer-events: none;
}

.documents .article-input-box::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(88.6deg, #FFFFFF 71%, rgba(91, 88, 230, 0.2) 87%);
    border-radius: inherit;
    z-index: -1;
    opacity: 0;
    transition: opacity .4s;
    pointer-events: none;
}
.documents .article-input-box:hover::before,
.documents .article-input-box:hover::after{
    opacity: 1;
}

.documents-tabs-links{
    display: flex;
    align-items: center;
    background: var(--color-white);
    padding: 3px;
    border-radius: 100px;
}
.documents-tabs-links li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #DADADA;
    padding: 10px 20px;
    display: block;
    border-radius: 100px;
    width: 100%;
    max-width: 100%;
}
.documents-tabs-links li a.active {
    color: var(--color-white);
    background: var(--color-blue);
}
.documents .selectbox .form-select {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--color-black);
    height: 50px;
    border-radius: 50px;
    padding: 10px 30px;
    border-color: var(--color-gray);
    background-image: url('../img/down-arrow.svg');
    background-size: 12px;
    margin-bottom: 25px;
    background-color: var(--color-white);
}

.documents .btns-group-crypto button{
    margin-left: 10px;
}
.documents .btns-group-crypto .btn-edit{
    opacity: .5;
}

/* My Documents Single css end */





/* Dark mode css */
.dark-mode .toggle-side-nav {
    color: #f9f9f9;
}

.dark-mode .close-sidebar-btn {
    color: #f9f9f9;
}

.dark-mode .side-navs li a:hover svg path, .dark-mode .side-navs li a.active svg path {
    fill: #f9f9f9;
}

.dark-mode .side-navs li a:hover, .dark-mode .side-navs li a.active {
    color: #f9f9f9;
}

.dark-mode .side-navs li a svg path {
    fill: #f9f9f9;
}
.dark-mode .generate-btn {
    color: var(--color-black);
}

.dark-mode .dropdown-menu {
    background: var(--color-white);
    box-shadow: 2px 2px 5px var(--color-gray);
}

.dark-mode .dropdown-menu li a {
    color: var(--color-black);
}

.dark-mode .dropdown-item {
    color: var(--color-black);
}

.dark-mode .user-box .dropdown-menu .user-info h3 {
    color: var(--color-black);
}

.dark-mode .upgrade-pro h2 {
    color: var(--color-black);
}

.dark-mode .upgrade-pro p {
    color: var(--color-black);
}
.dark-mode .article-page-btn:hover {
    color: var(--color-black);
}
.dark-mode .article-page-btn:hover .text-box .tag {
    color: var(--color-black);
}
.dark-mode .article-page-btn:hover .text-box p {
    color: var(--color-black);
}

.dark-mode .article-page-btn:hover .icon-box img{
    filter:brightness(5)
}
.dark-mode .subheadings.article-input-box .add-heading-btn:hover svg path {
    fill: var(--color-black);
}
.dark-mode .documents-tabs-links li a.active {
    color: var(--color-black);
}

.dark-mode .documents .article-input-box::after {
    background: linear-gradient(88.6deg, var(--color-white) 71%, rgba(91, 88, 230, 0.2) 87%);
}

/* .dark-mode .search-box input::placeholder {
    color: var(--color-text);
} */
html:has(.dark-mode)::-webkit-scrollbar-track{
    background: #1e2022;
}

.dark-mode .scroller::-webkit-scrollbar-track{
    background: var(--color-gray);
}

/* Dark mode css end */

.alert-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    font-size: 1.5rem;
}

.batch_keywords_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.keyword_list {
    background: rgba(91, 88, 230, 0.1) !important;
    border: 1px solid var(--color-blue);
    border-radius: 40px;
    overflow: hidden;
    padding: 6px;
    font-size: 12px;
    font-weight: 400;
    margin-right: 5px;
}

.keyword_list span:nth-child(1) {
    padding: 0.3em 0.5em;
    color: var(--color-blue);
}

.tagify__tag__removeBtn::after{
    content: "×";
    transition: .3s, color 0s;
    padding: 0.3em 0.5em;
}

.article-left-side .scroller {
    max-height: 730px;
}

.svg-icon {
    width: 14px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(102deg) brightness(103%) contrast(101%);
}

/* .title-input-box label, .title-input-box ::-webkit-input-placeholder {
    color: var(--color-black);
} */

th, td {
    border: 1px solid black;
}

.scroller > p table {
    width: 100%;
}

.note > p {
    font-size: 13px;
    color: var(--color-black);
}

.dark-mode input::placeholder, .dark-mode textarea::placeholder {
    color: var(--color-text);
}

.include_url {
    color: var(--color-black);
}

.page_not_found {
    height: 100%;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}