@media(max-width:1499px){
    .search-box {
        max-width: 380px;
    }
    .article-input-box{
        padding: 18px;
    }
    .article-right-content .empty-title{
        font-size: 35px;
    }
    .article-input-box .form-select{
        padding: 10px 20px;
    }
    .article-right-content  .article-text-box h3 {
        font-size: 20px;
        line-height: 30px;
    }

    /* bulk article result css */
    .crypto-box-wraper .title-wraper h2 {
        font-size: 22px;
    }
    .crypto-box-wraper .crypto-box {
        margin: 0 8px;
        padding: 13px;
        border-radius: 12px;
    }
    .bulk-article-search .search-wraper form {
        width: 180px;
    }
    .article-right-content.bulk .article-text-box .scroller {
        max-height: 640px;
    }
    .article-right-content .feature-image {
        height: 200px;
    }
    /* bulk article result css end */

    .documents .article-input-box .sec-title-box h2{
        font-size: 16px;
        line-height: 24px;
    }
    .documents .article-input-box .sec-title-box .btns-group {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    
    .documents .article-input-box .btns-group button {
        width: 32px;
        height: 32px;
    }
    
    .documents .article-input-box .btns-group button img {
        max-width: 16px;
        width: 100%;
    }
    

}

@media(max-width:1399px){
    .search-box {
        max-width: 320px;
        display: flex;
        align-items: center;
    }
    .search-box input {
        padding-right: 20px;
    }
    .article-right-content .empty-title {
        font-size: 28px;
        line-height: 1.2;
    }
    .generate-btn {
        padding: 12px 55px;
    }
    .article-right-content .article-text-box .article-btns-group button {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
    }
    
    .article-right-content .article-text-box .article-btns-group button img {
        width: 100%;
        max-width: 20px;
    }
    
    .article-right-content .article-text-box .article-btns-group p {
        margin-right: 10px;
        flex: 0 0 100%;
    }
    
    .article-right-content .article-text-box .article-btns-group {
        flex-wrap: wrap;
        justify-content: end;
        text-align: right;
        max-width: 200px;
    }
    
    .article-right-content .feature-image {
        height: 170px;
    }
    .crypto-box-wraper .title-wraper h2 {
        font-size: 24px;
    }
}

@media(max-width:1199px){
    .toggle-side-nav {
        display: inline-block;
        width: 35px;
        height: 35px;
        background: var(--color-blue);
        border-radius: 50%;
        color: var(--color-white);
        line-height: 35px;
    }
    .close-sidebar-btn {
        position: absolute;
        top: -10px;
        right: -10px;
        background: var(--color-blue);
        color: var(--color-white);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar {
        transform: translate3d(-300px, 0, 0);
        transform-origin: left;
        transition: transform .4s;
    }
    .sidebar.active {
        transform: translate3d(0, 0, 0);
        transform-origin: left;
    }
    .toggle-btn-wraper {
        gap: 20px;
        display: flex;
        align-items: center;
    }
    .toggle-btn-wraper form {
        width: 65%;
    }
    .search-box {
        max-width: unset;
        display: flex;
        align-items: center;
        height: 45px;
    }
    
    .search-box input {
        padding-right: 20px;
    }
    .settings-box button {
        width: 40px;
        height: 40px;
    }
    
    .settings-box button img {
        max-width: 22px;
        width: 100%;
    }
    
    .notification-box button {
        width: 40px;
        height: 40px;
    }
    
    .notification-box button img {
        width: 100%;
        max-width: 22px;
    }
    
    .user-box button .user-img {
        width: 44px;
        height: 44px;
    }
    
    .user-box button .user-info h3 {
        font-size: 14px;
    }
    
    .inner-content{
        max-width: 100%;
        margin: 0;
        /* transform: translate3d(0,0,0);
        transition: .4s; */
    }
    /* .inner-content.active{
        transform: translate3d(300px, 0, 0);
    } */
    
}


@media(max-width:991px){
    .toggle-btn-wraper form {
        width: 75%;
    }
    .toggle-mode-btn span {
        height: 30px;
    }
    .toggle-mode-btn svg {
        width: 1.25rem;
        height: 1.25rem;
    }
    .toggle-mode-btn .dark svg {
        width: 0.9rem;
        height: .9rem;
    }

    /* single article css */
    .articles-btn-wraper {
        padding: 50px 0 30px;
    }
    .generate-btn {
        padding: 12px 40px;
        margin-bottom: 30px;
    }
    /* single article css end */

    .article-left-side .article-main-heading {
        font-size: 26px;
        line-height: 34px;
        margin-top: 20px;
    }

    .bulk-article-search .search-wraper form {
        width: 240px;
    }
}

@media(max-width:767px){
    .sidebar .logo{
        margin-bottom: 30px;
    }
    .user-box button .user-info{
        display: none;
    }
    .toggle-btn-wraper {
        margin-top: 15px;
    }
    .toggle-btn-wraper form {
        width: calc(100% - 50px);
    }
    .user-box .dropdown-menu .user-info {
        padding: 6px 20px 10px;
        border-bottom: 1px solid #e7e7e7;
        margin-bottom: 10px;
        display: block;
    }
    
    .user-box .dropdown-menu .user-info h3 {
        font-size: 16px;
        margin-bottom: 4px;
    }
    
    .user-box .dropdown-menu .user-info p {
        font-size: 13px;
        color: var(--color-blue);
        margin-bottom: 0;
    }

    .inner-content{
        padding: 15px;
    }
    .articles-btn-wraper {
        padding: 10px 0 0px;
    }
    .article-page-btn {
        padding: 15px 15px;
        margin: 0 10px 20px;
    }
    
    .article-page-btn .text-box h2 {
        font-size: 16px;
        margin-bottom: 0px;
    }
    
    .article-page-btn .text-box p {
        font-size: 13px;
        line-height: 24px;
    }
    
    .icon-box {
        width: 35px;
        height: 35px;
    }
    
    .icon-box img {
        max-width: 20px;
        width: 100%;
    }
    
    .article-page-btn .text-box .tag {
        margin-bottom: 10px;
    }
    .article-input-box .form-select {
        margin-bottom: 15px;
        height: 42px;
    }
    .article-input-box .form-control {
        height: 42px;
    }
    .article-right-content .empty-title {
        font-size: 22px;
    }

    /* single article result css */
    .subheadings.article-input-box .add-heading-btn {
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
    }
    
    .article-right-content {
        padding: 15px;
    }
    .article-right-content .feature-image {
        height: 110px;
        border-radius: 10px;
    }
    
    .article-right-content .article-text-box .title-wraper {
        flex-direction: column-reverse;
    }
    
    .article-right-content .article-text-box .article-btns-group {
        justify-content: center;
        text-align: center;
    }
    
    .article-right-content .article-text-box h3 {
        font-size: 20px;
        align-self: self-start;
    }
    .generate-btn {
        padding: 10px 30px;
    }
    /* single article result css end*/
    
    .article-input-box .sec-title-box h2{
        font-size: 16px;
    }

    .crypto-box-wraper .crypto-box {
        margin-bottom: 15px;
        text-align: center;
    }
    .bulk-article-search {
        margin-top: 15px;
        display: block;
    }
    .bulk-article-search .search-wraper form {
        width: 100%;
        margin-top: 10px;
    }
    .article-input-box .btns-group button {
        width: 30px;
        height: 30px;
    }
    
    .article-input-box .btns-group button img {
        max-width: 18px;
    }
    .documents .article-input-box .sec-title-box h2 {
        font-size: 14px;
        line-height: 20px;
    }
    .documents .crypto-box-wraper .title-wraper h2 {
        font-size: 22px;
        margin-bottom: 10px;
        line-height: 24px;
    }
    
}